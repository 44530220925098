@charset "UTF-8";
@import "~ng-pick-datetime/assets/style/picker.min.css";
html {
  font-size: 16px;
}

/* You can add global styles to this file, and also import other style files */
.overflow-x-scroll {
  overflow-x: scroll;
}

.overflow-y-auto {
  overflow-y: auto;
}

button:focus {
  box-shadow: none !important;
  outline: none !important;
}

.z-index-1 {
  z-index: 1;
}

.z-index-2 {
  z-index: 2;
}

.z-index-3 {
  z-index: 3;
}

.z-index-4 {
  z-index: 4;
}

.top-0 {
  top: 0;
}

.bottom-0 {
  bottom: 0;
}

.left-0 {
  left: 0 !important;
}

.right-0 {
  right: 0 !important;
}

.w-s-no-wrap {
  white-space: nowrap;
}

.f-no-wrap {
  flex-wrap: nowrap;
}

.noselect {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.rotate {
  display: block;
  -webkit-transition-duration: 0.2s;
  -moz-transition-duration: 0.2s;
  -o-transition-duration: 0.2s;
  -webkit-transition-property: transform;
  -moz-transition-property: transform;
  -o-transition-property: transform;
  transition-property: transform;
}

.rotate-90 {
  -webkit-transform: rotate(-90deg);
  --moz-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  -o-transform: rotate(-90deg);
  transform: rotate(-90deg);
}

.rotate-180 {
  -webkit-transform: rotate(-180deg);
  --moz-transform: rotate(-180deg);
  -ms-transform: rotate(-180deg);
  -o-transform: rotate(-180deg);
  transform: rotate(-180deg);
}

.transition-500 {
  transition-duration: 500ms;
}

@font-face {
  font-family: "Material Icons";
  font-style: normal;
  font-weight: 400;
  src: url(/fonts/MaterialIcons-Regular.eot);
  /* For IE6-8 */
  src: local("Material Icons"), local("MaterialIcons-Regular"), url(/fonts/MaterialIcons-Regular.woff2) format("woff2"), url(/fonts/MaterialIcons-Regular.woff) format("woff"), url(/fonts/MaterialIcons-Regular.ttf) format("truetype");
}
.material-icons {
  font-family: "Material Icons";
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  /* Preferred icon size */
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: "liga";
}
.material-icons.x1 {
  font-size: 1rem;
}

.btn-transparent {
  border: none;
  background: #00FFFF00;
}

.btn-group-xs > .btn, .btn-xs {
  padding: 0.25rem 0.4rem;
  font-size: 0.875rem;
  line-height: 0.5;
  border-radius: 0.2rem;
}

.bg-black-097 {
  background-color: rgba(0, 0, 0, 0.97);
}

.bg-grey, .capture {
  background-color: #f3f3f3;
}

.center-xy, .modal-panel {
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.modal-panel {
  height: 70%;
  width: 70%;
  position: absolute;
}

.close-button {
  float: right;
  font-size: 1.2rem;
  background: none;
  padding: 0;
  color: red;
  border: none;
}
.close-button:after {
  content: "❌";
}

.capture {
  width: 100%;
  padding: 0.25rem 0 0.05rem 1rem;
}
.capture h5 {
  margin: 0 !important;
}

.capture-row, .capture-last-row {
  width: 100%;
  margin: 0.25rem 0 0 0;
  padding: 0 0.25rem;
}

.capture-last-row {
  margin: 0.25rem 0 0.5rem 0;
}

.cursor-pointer {
  cursor: pointer;
}

.flex-card .card-header, .flex-card .card-footer {
  padding: 0;
  text-align: center;
  flex: none;
}
.flex-card .card-header .absolute-left, .flex-card .card-footer .absolute-left {
  position: absolute;
  left: 0;
}
.flex-card .card-header {
  height: 41px;
  line-height: 41px;
}
.flex-card .card-body {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  padding: 0;
  /* width */
  /* Track */
  /* Handle */
}
.flex-card .card-body::-webkit-scrollbar {
  width: 12px;
  background-color: #F5F5F5;
}
.flex-card .card-body::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #F5F5F5;
}
.flex-card .card-body::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #A9A9A9;
  /* Handle on hover */
}
.flex-card .card-body::-webkit-scrollbar-thumb:hover {
  background: #555;
}
.flex-card .card-footer {
  height: 25px;
}

.icon-row img {
  width: 24px;
  height: 24px;
}

.popup {
  background-color: #FFFFFF;
}

.rounded-top-left {
  border-top-left-radius: 0.25rem !important;
}

.rounded-top-right {
  border-top-right-radius: 0.25rem !important;
}

.rounded-bottom-left {
  border-bottom-left-radius: 0.25rem !important;
}

.rounded-top-right {
  border-top-right-radius: 0.25rem !important;
}

.no-selection:hover, .no-selection:focus, .no-selection:active {
  background: none !important;
  border-color: #00FFFF00 !important;
}

/*#region Resizable Directive*/
.resize-container {
  position: relative;
}

.resize {
  display: flex;
  position: absolute;
}
.resize__left, .resize__right {
  top: 0;
  bottom: 0;
  width: 10px;
  height: auto;
  justify-content: center;
  cursor: e-resize;
}
.resize__right {
  right: -5px;
}
.resize__left {
  left: -5px;
}
.resize__top, .resize__top--left, .resize__top--right, .resize__bottom--left, .resize__bottom--right, .resize__bottom {
  left: 0;
  right: 0;
  height: 10px;
  width: auto;
  align-items: center;
  cursor: s-resize;
}
.resize__top--left, .resize__top--right, .resize__top {
  top: -5px;
}
.resize__top--left--left, .resize__top--left--right, .resize__top--right--left, .resize__top--right--right, .resize__top--left, .resize__top--right {
  width: 10px;
  height: 10px;
}
.resize__top--left--left, .resize__top--right--left, .resize__top--left {
  left: -5px;
  right: auto;
  cursor: se-resize;
}
.resize__top--left--right, .resize__top--right--right, .resize__top--right {
  right: -5px;
  left: auto;
  cursor: nesw-resize;
}
.resize__bottom--left, .resize__bottom--right, .resize__bottom {
  bottom: -5px;
}
.resize__bottom--left--left, .resize__bottom--left--right, .resize__bottom--right--left, .resize__bottom--right--right, .resize__bottom--left, .resize__bottom--right {
  width: 10px;
  height: 10px;
}
.resize__bottom--left--left, .resize__bottom--right--left, .resize__bottom--left {
  left: -5px;
  right: auto;
  cursor: nesw-resize;
}
.resize__bottom--left--right, .resize__bottom--right--right, .resize__bottom--right {
  right: -5px;
  left: auto;
  cursor: se-resize;
}

.cdk-virtual-scroll-content-wrapper {
  padding-right: 4px;
}

/*#endregion */