$alert-color: #BE0000;
$black-color: #000000;
$id-color: #5F9EA0;
$bg-grey: #f3f3f3;
$grey-color: #808080;
$hardware-color: #483D8B;
$splash-color: rgba(0, 0, 0, .97);
$speed-color: #CC2222;
$subdivision-color: #8D9BA7;
$transparent-color: #00FFFF00;
$white-color: #FFFFFF;
$white-smoke-color: #F5F5F5;
$ol-button-color: rgba(0,60,136,0.5);

$card-header-height: 41px;
$card-footer-height: 25px;
$transition-time: .2s;
$navbar-height: 40px;
$navbar-color: #b0b0f0;
$footer-color: $navbar-color;
$title-color: lighten($navbar-color, 10);
